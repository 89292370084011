import React, { useState } from 'react';
import { ContactMessageData, sendContactMessage } from 'src/api/sendContactMessage';
import { useClub } from 'src/context/useClub';
import { PrimaryButton } from 'src/shared/buttons';
import { ErrorMessage } from 'src/shared/layout/ErrorMessage';
import { Card } from 'src/shared/widgets/Card';
import { TextField } from 'src/shared/widgets/TextField/TextField';
import { TextareaField } from 'src/shared/widgets/TextField/TextareaField';

function validateContactMessage(data: ContactMessageData): string | null {
  if (!data.name.trim()) {
    return 'Please enter your name';
  }
  if (!data.email.trim()) {
    return 'Please enter your email address';
  }
  if (!data.message.trim()) {
    return 'Please enter a message';
  }
  return null;
}

interface ContactFormProps {
  className?: string;
}

export const ContactForm: React.FC<ContactFormProps> = ({ className }) => {
  const {
    club: { info },
  } = useClub();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  async function onSubmit() {
    setError(null);
    const data: ContactMessageData = { name, email, message };
    const errorMessage = validateContactMessage(data);
    if (errorMessage) return setError(errorMessage);

    setIsLoading(true);
    try {
      await sendContactMessage(data, info.code);
      setName('');
      setEmail('');
      setMessage('');
      setIsSuccess(true);
    } catch {
      setError('Something went wrong. Please try again later.');
    }
    setIsLoading(false);
  }

  return (
    <Card className={className}>
      <h4 className='mb-3'>Contact Us</h4>
      <p className='fs-6'>
        If you have any questions or would like to get in touch with us, please fill out the form below and we will get
        back to you as soon as possible.
      </p>
      <TextField isDisabled={isLoading} value={name} onChange={setName} title='Name' isRequired placeholder='Name' />
      <TextField
        isDisabled={isLoading}
        value={email}
        onChange={setEmail}
        title='Email'
        isRequired
        placeholder='Email'
      />
      <TextareaField
        isDisabled={isLoading}
        value={message}
        onChange={setMessage}
        title='Message'
        isRequired
        placeholder='Message'
      />
      {isSuccess && <div className='text-success mb-3'>Your message has been sent!</div>}
      {error && <ErrorMessage className='mb-3' message={error} />}
      <PrimaryButton disabled={isLoading} onClick={onSubmit} className='w-100'>
        Submit your query
      </PrimaryButton>
    </Card>
  );
};
