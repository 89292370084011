import React from 'react';
import { cx } from 'src/utils/styles';
import { abbreviateString } from 'src/utils/strings';
import css from './RoundedImage.module.scss';

interface RoundedImageProps {
  className?: string;
  name: string;
  url?: string | null;
  withBorder?: boolean;
}

export const RoundedImage: React.FC<RoundedImageProps> = ({ className, name, url, withBorder }) => {
  return (
    <div
      aria-label={name}
      className={cx(
        'd-flex align-items-center justify-content-center rounded-circle',
        css.avatar,
        url && css.hasUrl,
        withBorder && css.withBorder,
        className,
      )}
    >
      {url ? (
        <img
          alt={name}
          className='h-100 mh-100 w-100 mw-100 rounded-circle'
          /* crossOrigin='anonymous' */
          referrerPolicy='no-referrer'
          src={url}
        />
      ) : (
        <span>{abbreviateString(name || '', 2)}</span>
      )}
    </div>
  );
};
