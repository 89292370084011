import { apiClient } from 'src/utils/apiClient';
// import { dateToISONoTimezone } from 'src/utils/dates';

export interface ClubCompetition {
  id: number;
  name: string;
  ageGroup: string;
  category: string;
  oldestDob: string | null;
  youngestDob: string | null;
  event: {
    id: number | null;
    name: string | null;
    start: string | null;
    end: string | null;
    description: string | null;
    eventEntryDeadlineDate: string | null;
  };
  sport: {
    id: number;
    name: string;
  };
  product: {
    id: number | null;
    name: string | null;
    cost: number | null;
    link: string | null;
  };
  venue: {
    id: string | null;
    name: string | null;
    address: string | null;
    city: string | null;
    postCode: string | null;
    lat: number | null;
    lng: number | null;
  };
}

export type ClubCompetitions = ClubCompetition[];

interface ClubCompetitionsResponse {
  result: {
    competition_id: number;
    sport_id: number;
    sport_name: string;
    venue_id: string;
    venue_name: string;
    competition_category_age_group: string;
    competition_category_type: string;
    competition_name: string;
    has_competition_started: string;
    competition_min_dob_limit: DateTimeString | null;
    competition_max_dob_limit: DateTimeString | null;
    venue?: {
      venue_lat: string | null;
      venue_lng: string | null;
      venue_address1: string | null;
      venue_city: string | null;
      venue_postcode: string | null;
    };
    event: {
      event_description: string | null;
      event_id: number;
      event_name: string;
      event_start: DateTimeString;
      event_end: DateTimeString;
      event_entry_deadline_date: DateTimeString | null;
    };
    product: {
      stripe_product_id: number;
      stripe_product_name: string;
      stripe_product_cost: number | null;
      stripe_product_link: string | null;
    } | null;
  }[];
}

export async function getClubCompetitions(siteCode: string): Promise<ClubCompetitions> {
  const today = new Date();
  const sixMonthsFromToday = new Date(today.setMonth(today.getMonth() + 6));

  const { result } = await apiClient<ClubCompetitionsResponse>(
    `/v1/website/competitions?from_date=${new Date().toISOString()}&to_date=${sixMonthsFromToday.toISOString()}`,
    { siteCode },
  );

  return result.map(competition => ({
    id: competition.competition_id,
    name: competition.competition_name,
    ageGroup: competition.competition_category_age_group,
    category: competition.competition_category_type,
    oldestDob: competition.competition_min_dob_limit,
    youngestDob: competition.competition_max_dob_limit,
    event: {
      id: competition.event?.event_id || null,
      name: competition.event?.event_name || null,
      start: competition.event?.event_start || null,
      end: competition.event?.event_end || null,
      description: competition.event?.event_description || null,
      eventEntryDeadlineDate: competition.event?.event_entry_deadline_date || null,
    },
    sport: {
      id: competition.sport_id,
      name: competition.sport_name,
    },
    product: {
      id: competition.product?.stripe_product_id || null,
      name: competition.product?.stripe_product_name || null,
      cost: competition.product?.stripe_product_cost || null,
      link: competition.product?.stripe_product_link || null,
    },
    venue: {
      id: competition.venue_id,
      name: competition.venue_name,
      address: competition.venue?.venue_address1 || null,
      city: competition.venue?.venue_city || null,
      postCode: competition.venue?.venue_postcode || null,
      lat: competition.venue ? Number(competition.venue.venue_lat) : null,
      lng: competition.venue ? Number(competition.venue.venue_lng) : null,
    },
  }));
}
