import { message } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import { Card, CardTitle } from 'src/shared/widgets/Card';
import { createEntry } from 'src/api/createEntry';
import { useClub } from 'src/context/useClub';
import useForm from 'antd/es/form/hooks/useForm';
import { useState } from 'react';
import DefaultForm from './defaultForm';
// import BasketballEnglandForm from './basketballEnglandForm';
import { format } from 'date-fns';
import { stringFromJoinedParts, toTitleCase } from 'src/utils/strings';
import { Map } from 'src/shared/widgets/Map';
import { MapPin } from 'react-feather';
import { PrimaryButton } from 'src/shared/buttons';

export interface FormData {
  ageGroup: string;
  category: string;
  uniqueTeamGroupingCode: string;
  firstName: string;
  playerEmail: string;
  lastName: string;
  phoneNumber: string;
  allergies: string;
  britishPassport: string;
  consentsAgree: boolean;
  county: string;
  disabilities: string;
  parent1Email: string;
  parent2Email: string;
  relationship1: string;
  relationship2: string;
  emergencyFirstName1: string;
  emergencyFirstName2: string;
  emergencyLastName1: string;
  emergencyLastName2: string;
  emergencyMobileNumber1: string;
  emergencyMobileNumber2: string;
  ethnicity: string;
  dob: string;
  familyDeath: string;
  gender: string;
  headshotPhoto: File;
  homePostcode: string;
  jerseySize: string;
  medicalDetails: string;
  photographyConsentParent: string;
  photographyConsentPlayer: string;
  talentBehavioursAgree: boolean;
}

const CompetitionForm = () => {
  const [form] = useForm();
  const { competitionId } = useParams();
  const {
    club: { info, competitions },
  } = useClub();

  const location = useLocation();
  const { oldestDob, youngestDob, category } = location.state;

  const [email, setEmail] = useState<string | null>(null);
  const currentCompetition = competitions.find(({ id }) => id === parseInt(competitionId!));

  const isEnglandBasketball = info.code.includes('BE3x3');

  const [isEntrySubmitted, setIsEntrySubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const submit = async (values: FormData) => {
    setLoading(true);
    try {
      const data = {
        ...values,
      };
      form.resetFields();
      await createEntry(data, parseInt(competitionId!), info.code);
      setEmail(values.playerEmail ?? values.parent1Email);
      setIsEntrySubmitted(true);
      message.success('Entry created successfully');
      setLoading(false);
    } catch (err) {
      message.error('Failed to submit form');
      setLoading(false);
    }
  };

  const formattedAddress =
    currentCompetition?.venue.address && currentCompetition.venue.city && currentCompetition.venue.postCode
      ? stringFromJoinedParts([
          currentCompetition.venue.address,
          currentCompetition.venue.city,
          currentCompetition.venue.postCode,
        ])
      : '';

  const formatDate = (dateWithTime: string) => {
    const date = new Date(dateWithTime);
    const hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${formattedHours}:${minutes} ${period}`;
    return formattedTime;
  };

  const shouldRenderMap = currentCompetition?.venue.lat && currentCompetition?.venue.lng ? true : false;
  return (
    <div>
      <div className='d-flex flex-column gap-3 w-100'>
        <div className='col-12'>
          <Card>
            <div className='row'>
              <CardTitle className='mb-2'>{toTitleCase(currentCompetition?.name || '')}</CardTitle>
              <div className='col-md-6 d-flex flex-column justify-content-between'>
                {currentCompetition?.ageGroup && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Age group:</span> {toTitleCase(currentCompetition.ageGroup)}
                  </p>
                )}
                {currentCompetition?.category && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Gender:</span> {toTitleCase(currentCompetition.category)}
                  </p>
                )}
                {currentCompetition?.event.start && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Date:</span> {format(currentCompetition.event.start, 'dd MMMM yyyy')}
                  </p>
                )}
                {currentCompetition?.event.start && currentCompetition?.event.end && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Time:</span> {formatDate(currentCompetition.event.start)} to{' '}
                    {formatDate(currentCompetition.event.end)}
                  </p>
                )}
                {currentCompetition?.event.eventEntryDeadlineDate && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Entry deadline:</span>{' '}
                    {format(currentCompetition?.event.eventEntryDeadlineDate, 'dd MMMM yyyy - h:mm a')}
                  </p>
                )}
                {currentCompetition?.product.cost && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Entry cost:</span> £{currentCompetition.product.cost}
                  </p>
                )}
                {currentCompetition?.event.description && (
                  <p className='ts-fs-14'>
                    <span className='ts-fw-500'>Information:</span> {currentCompetition.event.description}
                  </p>
                )}
              </div>
              <div className='col-md-6'>
                <>
                  <CardTitle className='my-2'>Address</CardTitle>
                  <a
                    className='ts-fs-13'
                    href={`https://maps.google.com?q=${formattedAddress}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='d-flex'>
                      <MapPin className='me-1 align-middle ts-color-primary' />
                      <p className='ts-fs-14'>{formattedAddress}</p>
                    </div>
                  </a>
                  {shouldRenderMap && (
                    <div className='mt-2'>
                      <Map lat={currentCompetition!.venue.lat!} lng={currentCompetition!.venue.lng!} zoom={15} />
                    </div>
                  )}
                  <p className='ts-fs-14 mt-2'>
                    <span className='ts-fw-500'>Contact us:</span> {info.email}
                  </p>
                </>
              </div>
            </div>
          </Card>
        </div>
        <div className='col-12'>
          {isEntrySubmitted ? (
            <>
              <CardTitle className='m-2'>Step {isEnglandBasketball ? '5' : '2'} - Payment</CardTitle>
              <Card>
                <div>
                  <p className='ts-fs-14 ts-fw-500'>Your entry will be completed once you made the payment.</p>
                  {currentCompetition?.product.cost && (
                    <p className='ts-fs-14'>Entry Cost: £{currentCompetition.product.cost}</p>
                  )}
                  <p className='ts-fs-14'>
                    {currentCompetition?.product.link ? (
                      <PrimaryButton
                        onClick={() =>
                          window.open(
                            `${currentCompetition!.product
                              .link!}?prefilled_email=${email}&client_reference_id=competition_${
                              currentCompetition.id
                            }`,
                            '_blank',
                          )
                        }
                      >
                        <span>PAY NOW</span>
                      </PrimaryButton>
                    ) : (
                      'No payment link available'
                    )}
                  </p>
                </div>
              </Card>
            </>
          ) : (
            // <>
            //   {isEnglandBasketball ? (
            //     <BasketballEnglandForm submit={submit} loading={loading} />
            //   ) : (
            //     <DefaultForm submit={submit} loading={loading} />
            //   )}
            // </>
            <DefaultForm submit={submit} loading={loading} dobRange={{ oldestDob, youngestDob }} category={category} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompetitionForm;
