import React from 'react';
import { Card, CardSubtitle, CardTitle } from 'src/shared/widgets/Card';
import { cx } from 'src/utils/styles';
import { ClubTeam } from 'src/api/getClubTeams';
import { useClub } from 'src/context/useClub';
import css from './TeamCard.module.scss';
import { PrimaryButton } from 'src/shared/buttons';
import PlayersOnboardingInfo from './PlayersOnboardingInfo';

interface TeamAvatarProps {
  logoUrl?: string | null;
}

export const TeamAvatar: React.FC<TeamAvatarProps> = ({ logoUrl }) => {
  return (
    <div
      className={cx('h-100 m-sm-3 mb-sm-0', css.logo)}
      style={{ backgroundImage: `url('${logoUrl || '/assets/img/shield_place_holder.png'}')` }}
    />
  );
};

interface TeamCardProps {
  team: ClubTeam;
}

export const TeamCard: React.FC<TeamCardProps> = ({ team: { name, logoUrl, code } }) => {
  const { club } = useClub();
  const [modalOpened, setModalOpened] = React.useState(false);

  return (
    <Card className={cx(css.wrapper, 'p-0 d-flex flex-sm-column')}>
      {modalOpened && <PlayersOnboardingInfo close={setModalOpened} teamName={name} teamCode={code} />}

      <TeamAvatar logoUrl={logoUrl || club.info.logoUrl} />
      <div className='d-flex flex-column w-100 p-3 gap-3'>
        <div className={cx('flex-grow-1 flex-shrink-1 d-flex flex-column justify-content-sm-between')}>
          <CardTitle className='mb-2'>{name}</CardTitle>
          <CardSubtitle className={css.code}>Team code: {code}</CardSubtitle>
        </div>
        <PrimaryButton
          className='w-sm-auto ts-fs-14 ts-fw-500'
          style={{ padding: '0.75rem 1.5rem', height: 'auto' }}
          onClick={() => setModalOpened(true)}
        >
          JOIN ON APP
        </PrimaryButton>
      </div>
    </Card>
  );
};
