import React from 'react';
import { useClub } from 'src/context/useClub';
import { DropdownField, DropdownValue } from 'src/shared/widgets/DropdownField/DropdownField';
import { cx } from 'src/utils/styles';

interface FixturesFiltersProps {
  pickedTeam: DropdownValue;
  onTeamChange(team: DropdownValue): void;
  className?: string;
}

export const FixturesFilters: React.FC<FixturesFiltersProps> = ({ onTeamChange, pickedTeam, className }) => {
  const {
    club: { teams },
  } = useClub();

  return (
    <div className={cx('d-flex gap-3 gap-sm-5 w-100 flex-wrap', className)}>
      <div className='d-flex gap-2 align-items-center flex-grow-1'>
        <p className='mb-0'>Team</p>
        <DropdownField
          className='ts-box-shadow flex-grow-1'
          value={pickedTeam}
          onChange={onTeamChange}
          options={teams.map(team => ({ id: team.id.toString(), label: team.name }))}
        />
      </div>
    </div>
  );
};
