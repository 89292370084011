import React, { useRef } from 'react';
import { Download, Maximize2, Minimize, Pause, Play } from 'react-feather';
import { FastForwardIcon } from 'src/shared/assets';
import { PlaybackSpeed } from './PlaybackSpeed';
import { RewindIcon } from 'src/shared/assets';
import { VolumeControl } from './VolumeControl';
import { cx } from 'src/utils/styles';
import css from './VideoPlayerControls.module.scss';
import { downloadVideo } from 'src/shared/utils/features';
import { toast } from 'react-toastify';
import { TransparentButton } from 'src/shared/buttons';
import { useIsMobile } from 'src/utils/hooks/useIsMobile';
import { CSS_BREAKPOINTS } from 'src/constants';

export interface VideoPlayerControls {
  isVideoPlaying: boolean;
  videoUrl?: string;
  onRewind: () => void;
  onFastForward: () => void;
  onPlayPause: () => void;
  onChangeVolume: (volume: number) => void;
  muted: boolean;
  onMute: () => void;
  volume: number;
  playbackRate: number;
  onPlaybackRateChange: (rate: number) => void;
  onToggleFullScreen: () => void;
  isFullScreen: boolean;
  played: number;
  onSeek: (num: number) => void;
  onSeekMouseDown: (e: number) => void;
  onSeekMouseUp: (e: number) => void;
  elapsedTime: string;
  totalDuration: string;
}

const VideoPlayerControls: React.FC<VideoPlayerControls> = ({
  videoUrl,
  onPlayPause,
  isVideoPlaying,
  onRewind,
  onFastForward,
  muted,
  onMute,
  onChangeVolume,
  volume,
  playbackRate,
  onPlaybackRateChange,
  onToggleFullScreen,
  isFullScreen,
  played,
  onSeek,
  onSeekMouseDown,
  onSeekMouseUp,
  elapsedTime,
  totalDuration,
}) => {
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const [isClicking, setIsClicking] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const onProgressClick = (e: React.MouseEvent) => {
    if (!progressBarRef.current) return;

    const timelineRefRect = progressBarRef.current.getBoundingClientRect();
    const timelineStart = timelineRefRect.left;
    const timelineWidth = timelineRefRect.width;
    const mousePosition = e.pageX;
    const timelineCursorRelativePositionInPercents = (mousePosition - timelineStart) / timelineWidth;

    onSeek(timelineCursorRelativePositionInPercents);
  };

  const handleSeekMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!progressBarRef.current) return;

    const timelineRefRect = progressBarRef.current.getBoundingClientRect();
    const timelineStart = timelineRefRect.left;
    const timelineWidth = timelineRefRect.width;

    const mousePosition = e.pageX;
    const timelineCursorRelativePositionInPercents = (mousePosition - timelineStart) / timelineWidth;

    onSeekMouseUp(timelineCursorRelativePositionInPercents);
  };

  const handleSeekMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!progressBarRef.current) return;

    const timelineRefRect = progressBarRef.current.getBoundingClientRect();
    const timelineStart = timelineRefRect.left;
    const timelineWidth = timelineRefRect.width;

    const mousePosition = e.pageX;
    const timelineCursorRelativePositionInPercents = (mousePosition - timelineStart) / timelineWidth;
    onSeekMouseDown(timelineCursorRelativePositionInPercents);
  };

  const isMobile = useIsMobile(CSS_BREAKPOINTS.sm);

  async function handleDownload() {
    if (videoUrl) {
      try {
        setIsDownloading(true);
        await downloadVideo(videoUrl);
      } catch (err) {
        toast.error('Failed to download video');
      }
    }

    setIsDownloading(false);
  }

  React.useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      switch (e.code) {
        case 'ArrowLeft':
          return onRewind();
        case 'ArrowRight':
          return onFastForward();
        case 'Space':
          return onPlayPause();
        default:
          return;
      }
    }
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onFastForward, onPlayPause, onRewind]);

  return (
    <div>
      <div className={cx(css.container, 'gap-2')}>
        {isMobile ? (
          <>
            <span style={{ whiteSpace: 'nowrap', width: '34px' }}>{elapsedTime}</span>
            <div
              ref={progressBarRef}
              onMouseDown={e => {
                setIsClicking(true);
                onProgressClick(e);
                handleSeekMouseDown(e);
              }}
              onMouseMove={e => isClicking && onProgressClick(e)}
              onMouseUp={e => {
                setIsClicking(false);
                handleSeekMouseUp(e);
              }}
              style={{ cursor: 'pointer', width: '100%', padding: '5px 0' }}
            >
              <div className={css.progressBar}>
                <div
                  className={css.progress}
                  style={{
                    width: '100%',
                    transform: `scaleX(${played})`,
                    transformOrigin: 'left',
                  }}
                />
                <div className={css.progressDot} style={{ left: `${played * 100}%` }} />
              </div>
            </div>
            <span style={{ whiteSpace: 'nowrap' }}>{totalDuration}</span>
          </>
        ) : (
          ''
        )}
      </div>

      <div className={css.container}>
        <div className='d-flex gap-2'>
          <button className={css.button} onClick={onRewind}>
            <RewindIcon />
          </button>
          <button className={css.button} onClick={onPlayPause}>
            {isVideoPlaying ? <Pause /> : <Play />}
          </button>
          <button className={css.button} onClick={onFastForward}>
            <FastForwardIcon />
          </button>
        </div>

        {!isMobile ? (
          <>
            <span style={{ whiteSpace: 'nowrap', marginLeft: '15px', marginRight: '15px', width: '24px' }}>
              {elapsedTime}
            </span>
            <div
              ref={progressBarRef}
              onMouseDown={e => {
                setIsClicking(true);
                onProgressClick(e);
                handleSeekMouseDown(e);
              }}
              onMouseMove={e => isClicking && onProgressClick(e)}
              onMouseUp={e => {
                setIsClicking(false);
                handleSeekMouseUp(e);
              }}
              style={{ cursor: 'pointer', width: '100%', padding: '5px 0' }}
            >
              <div className={css.progressBar}>
                <div
                  className={css.progress}
                  style={{
                    width: '100%',
                    transform: `scaleX(${played})`,
                    transformOrigin: 'left',
                  }}
                />
                <div className={css.progressDot} style={{ left: `${played * 100}%` }} />
              </div>
            </div>
            <span style={{ whiteSpace: 'nowrap', marginLeft: '15px' }}>{totalDuration}</span>
          </>
        ) : (
          ''
        )}

        <VolumeControl onChangeVolume={onChangeVolume} onMute={onMute} muted={muted} volume={volume} />

        <PlaybackSpeed playbackRate={playbackRate} onChangeSpeed={onPlaybackRateChange} />

        <TransparentButton className={css.button} onClick={onToggleFullScreen}>
          {isFullScreen ? <Minimize className='ms-3' /> : <Maximize2 className='ms-3' />}
        </TransparentButton>

        <TransparentButton
          className={cx(css.button, isDownloading && css.download)}
          onClick={handleDownload}
          loading={isDownloading}
        >
          <Download className='ms-3' />
        </TransparentButton>
      </div>
    </div>
  );
};

export default VideoPlayerControls;
