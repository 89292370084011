import React from 'react';
import { Link } from 'react-router-dom';
import { cx } from 'src/utils/styles';
import { useAppRoutesContext } from 'src/routes/routes';
import css from './4xxPage.module.scss';

export const ErrorLoadingPage: React.FC = () => {
  const routes = useAppRoutesContext();

  return (
    <div className={cx('p-5 d-flex flex-column align-items-center flex-lg-row vh-100 overflow-hidden ts-fade-in-03')}>
      <div className={css.textWrapper}>
        <h1 className='ts-text-light ts-fw-600 ts-fs-75'>LOADI-</h1>
        <p className='ts-text-lighter ts-fw-500 ts-fs-16'>ERROR 408</p>
        <p className='ts-fs-16 mb-5'>We couldn't load the page for you. If this issue persists please let us know.</p>
        <p className='ts-fs-16'>
          Go back to <Link to={routes.home}>Home</Link>
        </p>
      </div>
      <div className={css.imageWrapper}>
        <img src='/assets/img/4xx/408.png' alt="Couldn't load" />
      </div>
    </div>
  );
};
