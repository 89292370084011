import React, { SVGAttributes, useState } from 'react';
import { useOnClickOutside } from 'src/utils/hooks/useOnClickOutside';
import { cx } from 'src/utils/styles';
import { ChevronDown } from 'react-feather';
import { DropdownPanel } from 'src/shared/widgets/DropdownField/DropdownPanel';
import { DropdownOption } from 'src/shared/widgets/DropdownField/DropdownOption';
import css from './DropdownField.module.scss';
import { RippleAnimation } from 'src/shared/layout/RippleAnimation';

const NoOptions: React.FC = () => {
  return <div className='p-3 ts-fs-13 fst-italic ts-text-light'>No options</div>;
};

export interface DropdownValue {
  id: string;
  label: string;
}
interface DropdownFieldProps {
  options: DropdownValue[];
  value: DropdownValue;
  onChange(value: DropdownValue): void;
  className?: string;
  isSmall?: boolean;
  icon?: React.ComponentType<SVGAttributes<SVGElement>>;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export const DropdownField: React.FC<DropdownFieldProps> = ({
  value,
  className,
  icon,
  isSmall,
  isDisabled,
  options,
  isLoading,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const fieldRef = useOnClickOutside(isOpen, close);
  const Icon = icon ?? null;

  return (
    <div
      className={cx(
        'position-relative',
        css.field,
        isOpen && css.fieldFocused,
        isDisabled && css.fieldDisabled,
        className,
      )}
      ref={fieldRef}
    >
      <button
        aria-label={value.label}
        className={cx('d-flex align-items-center w-100 px-3 py-2', css.fieldValue)}
        disabled={isDisabled}
        title={value.label}
        type='button'
        onClick={isOpen ? close : open}
      >
        <div className='flex-grow-1 ts-text-overflow text-start ts-text'>
          {Icon && <Icon className={cx(css.icon, 'me-2')} />}
          {value.label}
        </div>
        <div className={cx('ms-2', css.icon, isOpen && css.rotateIcon)}>
          <ChevronDown />
        </div>
      </button>

      <DropdownPanel isOpen={isOpen} isSmall={isSmall}>
        {isLoading ? (
          <RippleAnimation className='d-block my-3 mx-auto ts-color-primary' />
        ) : options.length > 0 ? (
          <>
            {options.length ? (
              options.map(option => (
                <DropdownOption
                  key={option.id}
                  option={option}
                  selected={option.id === value.id}
                  onSelect={selectedOption => {
                    onChange(selectedOption);
                    close();
                  }}
                />
              ))
            ) : (
              <NoOptions />
            )}
          </>
        ) : (
          <NoOptions />
        )}
      </DropdownPanel>
    </div>
  );
};
