export async function downloadVideo(videoUrl: string | null): Promise<void> {
    if (!videoUrl) return;
    const res = await fetch(videoUrl);
    const file = await res.blob();
  
    const tempUrl = URL.createObjectURL(file);
    const aTag = document.createElement('a');
    aTag.href = tempUrl;
    aTag.download = videoUrl.replace(/^.*[\\/]/, '');
    document.body.appendChild(aTag);
    aTag.click();
    URL.revokeObjectURL(tempUrl);
    aTag.remove();
  }