import { apiClient } from 'src/utils/apiClient';

export interface ContactMessageData {
  name: string;
  email: string;
  message: string;
}

export async function sendContactMessage(data: ContactMessageData, siteCode: string): Promise<void> {
  return apiClient(`/v1/website/contact`, {
    method: 'POST',
    body: JSON.stringify({ name: data.name.trim(), email: data.email.trim(), message: data.message.trim() }),
    siteCode,
  });
}
