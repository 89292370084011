import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { FullScreenSpinner } from 'src/shared/layout/FullScreenSpinner';
import { router } from 'src/routes/router';
import { ChakraProvider } from '@chakra-ui/react';

import 'src/styles/main.scss';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <ChakraProvider disableGlobalStyle={true}>
    <RouterProvider router={router} fallbackElement={<FullScreenSpinner />} />
  </ChakraProvider>,
);
