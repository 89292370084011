import { Outlet, createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from 'src/shared/layout/ErrorPage';
import { AppWrapper } from './AppWrapper';
import { APP_ROUTES } from './routes';
import { HomePage } from 'src/pages/home';
import { FixturesPage } from 'src/pages/fixtures';
import { ContactPage } from 'src/pages/contact';
import CompetitionForm from 'src/pages/CompetitionForm';

export const router = createBrowserRouter([
  {
    path: APP_ROUTES.home,
    element: <AppWrapper />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: APP_ROUTES.calendar,
        element: <FixturesPage />,
      },
      {
        path: APP_ROUTES.contact,
        element: <ContactPage />,
      },
      {
        path: APP_ROUTES.competitions.base,
        element: <Outlet />,
        children: [
          {
            path: ':competitionId',
            element: <CompetitionForm />,
          },
        ],
      },
    ],
  },
]);
