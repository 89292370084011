import React from 'react';
import { useAppRoutesContext } from 'src/routes/routes';
import { cx } from 'src/utils/styles';
import { Link } from 'react-router-dom';
import css from './4xxPage.module.scss';

export const UnauthorisedPage: React.FC = () => {
  const routes = useAppRoutesContext();

  return (
    <div className={cx('p-5 d-flex flex-column flex-lg-row vh-100 overflow-hidden ts-fade-in-03')}>
      <div className={css.textWrapper}>
        <h1 className='ts-text-light ts-fw-600 ts-fs-75'>OH NO</h1>
        <p className='ts-text-lighter ts-fw-500 ts-fs-16'>ERROR: 401</p>
        <p className='ts-fs-16 mb-5'>It seems we cannot let you pass as you do not have permissions.</p>
        <p className='ts-fs-16'>
          Go back to <Link to={routes.home}>Home</Link>
        </p>
      </div>
      <div className={css.imageWrapper}>
        <img src='/assets/img/4xx/401.png' alt='Not Authorised' />
      </div>
    </div>
  );
};
