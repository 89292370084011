import React, { CSSProperties } from 'react';
import { cx } from 'src/utils/styles';
import { SpinnerAnimation } from 'src/shared/widgets/SpinnerAnimation';
import css from './BaseButton.module.scss';

export interface BaseButtonProps {
  children: string | React.ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  selected?: boolean;
  title?: string;
  noCapitalize?: boolean;
  style?: CSSProperties;
  tabIndex?: number;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

export const BaseButton: React.FC<BaseButtonProps> = ({
  className,
  children,
  disabled,
  loading,
  selected,
  onClick,
  noCapitalize = false,
  ...rest
}) => {
  let title = '';
  if (disabled) {
    title = 'Button disabled until all input fields contain valid data';
  }
  if (loading) {
    title = 'Please wait';
  }

  return (
    <button
      aria-label={title}
      className={cx(
        'user-select-none',
        !noCapitalize && 'text-capitalize',
        css.button,
        loading && css.loading,
        selected && css.selected,
        className,
      )}
      disabled={disabled}
      title={title}
      type='button'
      onClick={disabled || loading || selected ? undefined : event => onClick && onClick(event)}
      {...rest}
    >
      {loading ? <SpinnerAnimation className='mx-auto' /> : children}
    </button>
  );
};
