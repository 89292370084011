import React from 'react';
import ReactDOM from 'react-dom';
import { cx } from 'src/utils/styles';
import { SpinnerAnimation } from 'src/shared/layout/SpinnerAnimation';
import css from './FullScreenSpinner.module.scss';

export const FullScreenSpinner: React.FC = () => {
  return ReactDOM.createPortal(
    <div className={cx(css.overlay, 'd-flex align-items-center justify-content-center')}>
      <SpinnerAnimation className={css.spinner} />
    </div>,
    document.getElementById('root') as HTMLElement,
  );
};
