import React, { useState } from 'react';
import { Volume, Volume1, Volume2, VolumeX } from 'react-feather';
import styles from './VolumeControl.module.scss';

export interface VolumeControlProps {
  onChangeVolume: (volume: number) => void;
  onMute: () => void;
  muted: boolean;
  volume: number;
}

export const VolumeControl: React.FC<VolumeControlProps> = ({ onChangeVolume, onMute, muted, volume }) => {
  const [showMenu, setShowMenu] = useState(false);

  const [currentVideoVolume, setCurrentVideoVolume] = useState(volume * 100);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let VolumeIcon: React.FC<{ style: any; onClick: any }> = Volume;

  if (currentVideoVolume === 0 || muted) {
    VolumeIcon = VolumeX;
  }
  if (currentVideoVolume > 0) {
    VolumeIcon = Volume;
  }
  if (currentVideoVolume > 50) {
    VolumeIcon = Volume1;
  }
  if (currentVideoVolume > 75) {
    VolumeIcon = Volume2;
  }

  const onVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const volume = +e.target.value;
    setCurrentVideoVolume(volume);
    onChangeVolume(volume / 100);
  };

  const onShowMenu = () => {
    setShowMenu(true);
  };

  const onHideMenu = () => {
    setShowMenu(false);
  };

  return (
    <div className='ms-3 position-relative' onMouseEnter={onShowMenu} onMouseLeave={onHideMenu}>
      <VolumeIcon style={{ height: '1.8em', cursor: 'pointer' }} onClick={onMute} />

      {showMenu && (
        <div className={styles.box}>
          <div className={styles.container}>
            <div className={styles.wrapper}>
              <input type='range' value={currentVideoVolume} onChange={onVolumeChange} className={styles.input} />
              <div className={styles.volumeMeter} style={{ width: `${currentVideoVolume}%` }} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
