import { toast } from 'react-toastify';

export function stringIsEmailAddress(email: string): boolean {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

export function stringIsOnlyAlphabetic(value: string): boolean {
  const regex = /^[A-Za-z]+$/;
  return regex.test(value);
}

export function abbreviateString(value: string, maxLength?: number): string {
  const abbreviation = value
    .split(' ')
    .map(word => word.charAt(0).toUpperCase())
    .join('');
  return maxLength ? abbreviation.slice(0, maxLength) : abbreviation;
}

export function stringFromJoinedParts(values: (string | null | undefined)[], separator = ', '): string {
  return values.reduce<string>((result, value, index) => {
    if (typeof value === 'string') {
      const trimmed = value.trim();
      if (trimmed.length > 0) {
        result += `${index > 0 ? separator : ''}${trimmed}`;
      }
    }
    return result;
  }, '');
}

export function toTitleCase(string: string): string {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function stringIsUrl(string: string): boolean {
  if (string === '') return true;
  const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;
  return regex.test(string);
}

const copyText = (
  text: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (response: { state: string; message: string; text: string; error?: any }) => void,
) => {
  if (navigator.clipboard !== undefined) {
    //Chrome
    navigator.clipboard
      .writeText(text)
      .then(() =>
        callback({
          state: 'success',
          message: 'Copied to clipboard',
          text,
        }),
      )
      .catch(err =>
        callback({
          state: 'failed',
          message: 'Could not copy text: ',
          error: err,
          text,
        }),
      );
  } else {
    callback({
      state: 'success',
      message: 'Copied to clipboard',
      text,
    });
  }
  // }
};
export const handleCopy = (text: string) => {
  copyText(text, res => {
    toast.info(res.message);
  });
};
