import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import css from './Accordion.module.scss';
import { cx } from 'src/utils/styles';

interface AccordionProps {
  children: React.ReactNode;
  title: string;
  isDefaultOpen?: boolean;
  actions?: (isOpen: boolean) => React.ReactNode;
}

export const Accordion: React.FC<AccordionProps> = ({ title, children, actions, isDefaultOpen = false }) => {
  const [isOpen, toggle] = React.useReducer(p => !p, isDefaultOpen);

  return (
    <div className={cx(css.wrapper, !isOpen && css.close)}>
      <button
        onClick={toggle}
        className={cx('w-100 p-3 d-flex justify-content-between align-items-center', css.button)}
      >
        <div className='d-flex justify-content-between align-items-center w-100 mx-2'>
          <p className='mb-0 ts-fs-18 ts-fw-500'>{title}</p>
          {actions ? actions(isOpen) : null}
        </div>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      <div className={cx('p-3 pt-0', isOpen ? css.show : css.hide)}>{children}</div>
    </div>
  );
};
