import { useState } from 'react';
import React from 'react';
import { ModalDialog } from 'src/shared/modal/ModalDialog';
import css from './TeamCard.module.scss';
import { cx } from 'src/utils/styles';
import { useIsMobile } from 'src/utils/hooks/useIsMobile';
import { CSS_BREAKPOINTS } from 'src/constants';
import QRCode from 'react-qr-code';
import { BASE_API_URL } from 'src/utils/apiClient';
import { handleCopy } from 'src/utils/strings';

export default function PlayersOnboardingInfo({
  close,
  teamName,
  teamCode,
}: {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  teamName: string;
  teamCode: string;
}) {
  const [copyTextTerm, setCopyTextTerm] = useState('Copy');
  const isMobile = useIsMobile(CSS_BREAKPOINTS.md);
  const copyText = () => {
    handleCopy(teamCode);
    setCopyTextTerm('Copied!');
    setTimeout(() => {
      setCopyTextTerm('Copy');
    }, 3000);
  };

  return (
    <ModalDialog closeDialog={() => close(false)}>
      <div className={cx('d-flex gap-4 wrapper', css.onboardingInfo)}>
        <div>
          <h1>
            <span className='ts-color-primary'>PLAYER ONBOARDING</span> PROCESS
          </h1>
          <section className='mb-3'>
            <h2>
              <span className='ts-color-primary'>1.</span> Download the app
            </h2>
            <div className={`d-flex ${isMobile ? 'gap-2' : 'gap-4'}`}>
              <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
                {!isMobile ? <img width={140} src='/assets/img/apple-app-qr.jpg' alt='appstore' /> : null}
                <a href='https://apps.apple.com/us/app/teamsportz/id1541456437' target='_blank'>
                  <img width={isMobile ? 100 : 180} src='/assets/img/download-appstore.png' alt='appstore' />
                </a>
              </div>
              <div className='d-flex flex-column align-items-center justify-content-center gap-2'>
                {!isMobile ? <img width={140} src='/assets/img/android-app-qr.jpg' alt='appstore' /> : null}
                <a
                  href='https://play.google.com/store/apps/details?id=com.teamsportz.store&hl=en&gl=US&pli=1'
                  target='_blank'
                >
                  <img width={isMobile ? 100 : 180} src='/assets/img/download-playstore.png' alt='playstore' />
                </a>
              </div>
            </div>
          </section>
          <section className='mb-3'>
            <h2>
              <span className='ts-color-primary'>2.</span> Create your account and login
            </h2>
          </section>
          <section className='mb-3'>
            <h2>
              <span className='ts-color-primary'>3.</span>{' '}
              {!isMobile ? 'Scan the QR code to join your team' : 'Enter the team code in the app'}
            </h2>
            {!isMobile ? (
              <div className='d-flex gap-3'>
                <QRCode size={140} value={`${BASE_API_URL}/join/${teamCode}`} />
                <div className='d-flex flex-column justify-content-end'>
                  <h3 className={cx('ts-fw-500 my-0 py-0', css.teamName)}>{teamName}</h3>
                  <div className={cx('d-flex gap-2 align-items-center', isMobile && 'flex-column')}>
                    <p className={cx('mb-0 ts-text-lighter', css.enterTeamCode, !isMobile && 'fs-3')}>
                      or, enter the team code in the app:{' '}
                    </p>
                    <h3
                      className='ts-color-primary ts-fw-500 mb-0'
                      style={{ fontFamily: 'monospace', wordBreak: 'keep-all' }}
                    >
                      {teamCode}
                    </h3>
                  </div>
                </div>
              </div>
            ) : (
              <h3 className='ts-fw-500 mb-0 d-flex gap-2 align-items-center' style={{ fontFamily: 'monospace' }}>
                Team Code: {teamCode}{' '}
                <span onClick={copyText} className={cx('d-flex', css.copyText)}>
                  <img src='/assets/icon/copy.svg' /> {copyTextTerm}
                </span>
              </h3>
            )}
          </section>
        </div>

        <div>{!isMobile ? <img width={470} src='/assets/img/mobile-showcase.jpg' alt='mobile app' /> : null}</div>
      </div>
    </ModalDialog>
  );
}
