import React from 'react';
import { ChevronsDown, ChevronsRight } from 'react-feather';
import { cx } from 'src/utils/styles';
import css from './ErrorMessage.module.scss';

interface ErrorMessageProps {
  className?: string;
  message: string | JSX.Element;
  isMessageBold?: boolean;
  showStackByDefault?: boolean;
  stack?: string;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  className,
  message,
  isMessageBold = true,
  stack,
  showStackByDefault,
}) => {
  const [stackVisible, setStackVisible] = React.useState(showStackByDefault || false);

  return (
    <div className={cx('text-break', stack && 'pb-1', css.errorMessage, className)}>
      <div className={cx(isMessageBold && 'ts-fw-500', css.message)}>{message}</div>

      {stack && !showStackByDefault && (
        <button
          className={cx('d-flex align-items-center mt-2 ts-fw-500 p-0 m-0', css.stackHandle)}
          type='button'
          onClick={() => setStackVisible(v => !v)}
        >
          {stackVisible ? (
            <>
              <ChevronsDown /> Hide stack trace
            </>
          ) : (
            <>
              <ChevronsRight /> Show stack trace
            </>
          )}
        </button>
      )}

      {stack && stackVisible && (
        <pre className='text-start text-break fst-italic mt-2 pb-3 ts-fs-14 ts-text-break-all'>{stack.trim()}</pre>
      )}
    </div>
  );
};
