import React from 'react';
import { cx } from 'src/utils/styles';
import { FormFieldWrapper } from 'src/shared/widgets/FormFieldWrapper/FormFieldWrapper';
import css from './TextField.module.scss';

interface TextareaFieldProps {
  className?: string;
  focusOnMount?: boolean;
  value: string;
  title: string;
  isDisabled?: boolean;
  isErrorVisible?: boolean;
  placeholder?: string;
  isRequired?: boolean;
  errorMessage?: string;
  maxLength?: number;
  onChange(value: string): void;
}

export const TextareaField: React.FC<TextareaFieldProps> = ({
  className,
  focusOnMount,
  value,
  onChange,
  title,
  errorMessage,
  isDisabled,
  isErrorVisible,
  isRequired,
  placeholder,
  maxLength = 500,
}) => {
  const ref = React.useRef<HTMLTextAreaElement>(null);
  const [height, setHeight] = React.useState(0);
  const characterCount = value.trim().length;

  React.useEffect(() => {
    if (ref.current) {
      const rowsCount = ref.current.value.split('\n').length - 1;
      const minHeight = rowsCount * 14 + 33;
      setHeight(minHeight);
    }
  }, [value, setHeight]);

  React.useEffect(() => {
    if (!ref.current || !focusOnMount) return;
    ref.current.focus();
  }, [focusOnMount]);

  return (
    <FormFieldWrapper title={title} isErrorVisible={isErrorVisible} errorMessage={errorMessage} isRequired={isRequired}>
      <div className={cx('position-relative', css.wrapperClassName)}>
        <textarea
          aria-label={title}
          className={cx('w-100 d-block', css.field, css.textarea, isErrorVisible && css.invalidField, className)}
          name={title}
          disabled={isDisabled}
          required={isRequired}
          ref={ref}
          rows={4}
          style={{ height: `${height + 2}px` }}
          title={title}
          value={value}
          placeholder={placeholder}
          onChange={event => onChange(event.target.value)}
        />

        <div className={cx('position-absolute', css.textareaCounter, characterCount > maxLength && css.overLimit)}>
          {characterCount} / {maxLength}
        </div>
      </div>
    </FormFieldWrapper>
  );
};
