import React from 'react';
import { TeamCard } from './TeamCard';
// import { Gallery } from 'src/shared/widgets/Gallery';
import { useClub } from 'src/context/useClub';
import { CompetitionCard } from './CompetitionCard';
import LatestHighights from './LatestHighights';
import { SimpleGrid } from '@chakra-ui/react';
import { HorizontalSelect } from 'src/shared/horizontalSelect/HorizontalSelect';
import { ClubCompetition } from 'src/api/getClubCompetitions';
import { getAllTimeDates } from 'src/utils/dates';
import { useHighlightsQuery } from './LatestHighights/highlightsQuery';

export const HomePage: React.FC = () => {
  const {
    club: { info, teams, competitions },
  } = useClub();

  const allTimeDates = getAllTimeDates();
  const highlightQuery = useHighlightsQuery({
    page: 1,
    limit: 3,
    filters: {
      fromDate: allTimeDates.startDate,
      toDate: allTimeDates.endDate,
      withVideo: true,
      siteCode: info.code,
    },
    sortAscending: false,
    sortBy: 'created',
  });

  const beCompetitionGroupings: Record<string, ClubCompetition[]> = {};

  for (const competition of competitions) {
    const competitionArea = competition.event.name ?? '';

    if (beCompetitionGroupings[competitionArea]) {
      beCompetitionGroupings[competitionArea] = [...beCompetitionGroupings[competitionArea], competition];
    }
    if (!beCompetitionGroupings[competitionArea] && !!competitionArea) {
      beCompetitionGroupings[competitionArea] = [competition];
    }
  }

  const beGroupsNames = Object.keys(beCompetitionGroupings);

  const commonTailStringArray = [];
  let breakLoop = false;
  for (let i = 0; i < 10; i++) {
    let currentWord = '';
    for (const name of beGroupsNames) {
      if (!currentWord) {
        currentWord = name.split(' ').reverse()[i];
      } else {
        if (currentWord !== name.split(' ').reverse()[i]) {
          breakLoop = true;
        }
      }
    }
    if (breakLoop) break;
    commonTailStringArray.push(currentWord);
  }

  const commonTailString = commonTailStringArray.reverse().join(' ');

  const beOptions = beGroupsNames.map(item => ({ id: item, label: item.replace(commonTailString, '') }));
  const [tab, setTab] = React.useState(beGroupsNames[0]);

  const TABS: Record<string, string> = {};

  for (const name of beGroupsNames) {
    TABS[name] = name;
  }

  return (
    <div>
      {competitions.length > 0 && info.showCompetitions && (
        <>
          <h3>{info.competitionsTitle || 'Our Competitions'}</h3>
          {info.code === 'BE3x3' ? (
            <>
              <HorizontalSelect
                className='mb-2 mt-4'
                options={beOptions}
                onSelect={({ id }) => setTab(id)}
                selectedId={tab}
              />

              <div className='mb-2'>
                <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
                  {beCompetitionGroupings[tab].map(competition => (
                    <CompetitionCard key={competition.id} competition={competition} />
                  ))}
                </SimpleGrid>
              </div>
            </>
          ) : (
            <>
              <div className='mb-2'>
                <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
                  {competitions.map(competition => (
                    <CompetitionCard key={competition.id} competition={competition} />
                  ))}
                </SimpleGrid>
              </div>
            </>
          )}
        </>
      )}

      {teams.length > 0 && info.showTeams && (
        <div className='mt-2'>
          <h3>{info.teamsTitle || 'Our Teams'}</h3>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 4 }} spacing={4}>
            {teams.map(team => (
              <TeamCard key={team.id} team={team} />
            ))}
          </SimpleGrid>
        </div>
      )}

      {highlightQuery.data?.highlights.length ? (
        <div className='mt-4'>
          <h3>Latest Highlights</h3>
          <LatestHighights highlightQuery={highlightQuery.data} />
        </div>
      ) : null}
    </div>
  );
};

{
  /* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      {data.map((item) => (
        <TransactionCard trxInfo={item} key={item.id} />
      ))}
    </SimpleGrid> */
}
