import { useOnElementResize } from 'src/utils/hooks/useOnElementResize';
import css from './DataTable.module.scss';
import { DataTableColumnHeader } from './DataTableColumnHeader';
import { Card } from '../Card';
import { cx } from 'src/utils/styles';
import { ReactNode, useState } from 'react';

interface DataTableDataItem extends AnyObject {
  id: number;
}

export interface DataTableColumnDef<T> {
  className?: string;
  key: string;
  overflowBreakpoint?: number;
  title?: string | ReactNode;
  width: number;
  dataCell(dataItem: T): JSX.Element | JSX.Element[] | string | null;
  dialogDataCell?(props: T): JSX.Element | null;
}

interface DataTableProps<T extends DataTableDataItem> {
  data: T[];
  columns: DataTableColumnDef<T>[];
  idPropName?: keyof T;
  cardClassName?: string;
  tableRowClassName?: string;
  wrapperClassName?: string;
  headerClassName?: string;
  stripped?: boolean;
  noDataMessage?: string;
}

export const DataTable = <T extends DataTableDataItem>({
  data,
  cardClassName,
  tableRowClassName,
  wrapperClassName,
  headerClassName,
  noDataMessage,
  stripped,
  idPropName = 'id',
  columns,
}: DataTableProps<T>): JSX.Element => {
  const [width, setWidth] = useState(0);
  const tableWrapperRef = useOnElementResize(({ width: newWidth }) => {
    setWidth(newWidth);
  });

  return (
    <div ref={tableWrapperRef} className={wrapperClassName}>
      <div
        className={cx(
          'd-flex align-items-center justify-content-between ts-fs-13',
          css.tableHeaderRow,
          headerClassName,
          tableRowClassName,
        )}
      >
        {columns.map(column => (
          <DataTableColumnHeader
            key={column.key}
            title={column.title || ''}
            width={column.width}
            className={column.className}
            overflows={Number(column.overflowBreakpoint) > width}
          />
        ))}
      </div>

      <Card className={cx(css.tableCard, cardClassName)}>
        {data.length > 0 ? (
          data.map(dataItem => (
            <div key={dataItem[idPropName]} className={cx(tableRowClassName, css.tableRow)}>
              <div className={cx('d-flex align-items-center justify-content-between')}>
                {columns.map((column, cIdx) => {
                  if (Number(column.overflowBreakpoint) > width) return null;

                  return (
                    <div
                      className={cx(
                        'text-break',
                        css.tableCell,
                        column.className,
                        stripped && cIdx % 2 !== 0 && css.stripped,
                      )}
                      key={column.key}
                      style={{ width: column.width }}
                    >
                      {column.dataCell(dataItem)}
                    </div>
                  );
                })}
              </div>
            </div>
          ))
        ) : (
          <p className='py-5 text-center'>{noDataMessage || 'No data'}</p>
        )}
      </Card>
    </div>
  );
};
