import { useQuery, UseQueryResult } from 'react-query';
// import { useAuthContext } from 'src/context/authContext';
// import { getHighlights } from 'src/pages/highlights/archive/api/getHighlights';
import { getHighlights } from './getHighlights';
// import { Highlight } from 'src/pages/highlights/domain';
// import { HIGHLIGHTS_QUERY_KEYS } from 'src/pages/highlights/queryKeys';
// import { TS_VIDEO_TYPE } from 'src/entities/videos/domain';

export interface Highlight {
  created: Date;
  name: string;
  tag: string;
  tagAbbr: string;
  tagCategory: string;
  tagTimestamp: number;
  thumbnail: string | null;
  // teamId: number;
  teamCode: string | null;
  teamName: string;
  id: number;
  url: string;
  videoId: number;
  player: { id: number; firstName: string; lastName: string; avatarUrl: string | null } | null;
  highType: string;
  videoName: string;
}

export interface QueryResponse {
  highlights: Highlight[];
  total: number;
}

interface QueryInput {
  filters: {
    category?: string;
    collectionId?: string;
    fromDate?: Date;
    tagName?: string;
    tagId?: string;
    // teamId?: string;
    toDate?: Date;
    videoId?: number;
    withVideo?: boolean;
    playerId?: number;
    tagsIds?: number[];
    siteCode: string;
  };
  sortAscending: boolean;
  sortBy: string;
  page: number;
  limit: number;
}

export function useHighlightsQuery(input: QueryInput): UseQueryResult<QueryResponse, Error> {
  //   const { token } = useAuthContext();

  return useQuery<QueryResponse, Error>(
    ['highlights', 'filtered', input],

    async () => {
      const response = await getHighlights(input);
      return {
        highlights:
          response.highlights?.data.map<Highlight>(item => ({
            id: item.high_id,
            name: item.video_name,
            created: new Date(item.created_at),
            // type: item.video_type as TS_VIDEO_TYPE,
            // teamId: item.team_id,
            tag: item.tag_name,
            tagAbbr: item.tag_abbreviation,
            tagCategory: item.tag_category,
            tagTimestamp: Number(item.high_timestamp),
            teamName: item.team_name,
            thumbnail: item.high_thumbnail_url,
            url: item.high_video_url || '',
            videoId: item.video_id,
            teamCode: null,
            // drawings: JSON.parse(item.high_canvas || '{}') as FinishedDrawings,
            highType: item.high_type || '',
            videoName: item.video_name || '',
            player:
              item.profile_first_name && item.user_id && item.profile_last_name
                ? {
                    firstName: item.profile_first_name,
                    lastName: item.profile_last_name,
                    avatarUrl: item.profile_avatar,
                    id: item.user_id,
                  }
                : null,
          })) || [],
        total: response.highlights?.total || 0,
      };
    },
    { enabled: !!input.filters.toDate },
  );
}
