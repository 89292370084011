import React from 'react';
import css from './DataTable.module.scss';
import { cx } from 'src/utils/styles';

interface DataTableColumnHeaderProps {
  overflows: boolean;
  width: number;
  title: string | React.ReactNode;
  className?: string;
}

export const DataTableColumnHeader: React.FC<DataTableColumnHeaderProps> = ({ overflows, title, width, className }) => {
  if (overflows) return null;

  return (
    <div className={cx('ts-text-overflow text-left', css.tableCell, className)} style={{ width: width }}>
      {title}
    </div>
  );
};
