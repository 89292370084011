import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppRoutesContext } from 'src/routes/routes';
import { cx } from 'src/utils/styles';
import { useClub } from 'src/context/useClub';
import css from './TopBar.module.scss';

interface TopBarProps {}

export const TopBar: React.FC<TopBarProps> = () => {
  const {
    club: {
      info: { name, showCalendar, showContactUs },
    },
  } = useClub();
  const routes = useAppRoutesContext();

  return (
    <div className={cx(css.wrapper, 'd-flex align-self-center align-items-center w-100 px-4 px-sm-5')}>
      <h3 className='d-none d-sm-block mb-0 ts-text-overflow'>{name.toUpperCase()}</h3>
      <div className='d-flex gap-4 align-self-center flex-grow-1 justify-content-sm-end flex-shrink-0'>
        <NavLink className={({ isActive }) => cx(isActive && 'ts-color-primary')} to={routes.home}>
          HOME
        </NavLink>
        {showCalendar && (
          <NavLink className={({ isActive }) => cx(isActive && 'ts-color-primary')} to={routes.calendar}>
            CALENDAR
          </NavLink>
        )}
        {showContactUs && (
          <NavLink className={({ isActive }) => cx(isActive && 'ts-color-primary')} to={routes.contact}>
            CONTACT US
          </NavLink>
        )}
      </div>
    </div>
  );
};
