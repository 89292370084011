import React, { useState } from 'react';
import { FixturesFilters } from './FixturesFilters';
import { useClub } from 'src/context/useClub';
import { DropdownValue } from 'src/shared/widgets/DropdownField/DropdownField';
import { GroupedFixtures } from './GroupedFixtures';

export const FixturesPage: React.FC = () => {
  const {
    club: { teams },
  } = useClub();

  const defaultTeam = teams[0];
  const [pickedTeam, setPickedTeam] = useState<DropdownValue>({
    id: defaultTeam.id.toString(),
    label: defaultTeam.name,
  });

  const team = teams.find(team => team.id === Number(pickedTeam.id));

  return (
    <div>
      <h2 className='mb-3'>Calendar</h2>
      <FixturesFilters pickedTeam={pickedTeam} onTeamChange={setPickedTeam} className='mb-4' />
      <GroupedFixtures fixture={team?.fixtures || []} />
    </div>
  );
};
