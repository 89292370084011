import { cx } from 'src/utils/styles';
import { FacebookLogo } from '../assets';
import { InstagramLogo } from '../assets';
import { TwitterLogo } from '../assets';
import { YoutubeLogo } from '../assets';
import { useClub } from 'src/context/useClub';
import css from './Hero.module.scss';

interface HeroProps {}

export const Hero: React.FC<HeroProps> = () => {
  const {
    club: { info },
  } = useClub();

  return (
    <div
      className={cx(css.hero, 'd-flex flex-column align-items-center justify-content-center position-relative')}
      style={{ backgroundImage: `url('${info.headerUrl || '/assets/img/hero.png'}')` }}
    >
      <img className={css.logo} src={info.logoUrl || '/assets/img/shield_place_holder.png'} alt='Logo' />
      <h2 className='mt-4'>{info.tagline.toUpperCase()}</h2>
      <div className={cx(css.social, 'position-absolute d-flex flex-column h-100')}>
        {info.instagramUrl && <InstagramLogo onClick={() => window.open(info.instagramUrl as string, '_blank')} />}
        {info.twitterUrl && <TwitterLogo onClick={() => window.open(info.twitterUrl as string, '_blank')} />}
        {info.youtubeUrl && <YoutubeLogo onClick={() => window.open(info.youtubeUrl as string, '_blank')} />}
        {info.facebookUrl && <FacebookLogo onClick={() => window.open(info.facebookUrl as string, '_blank')} />}
      </div>
    </div>
  );
};
