import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import useDeepCompareEffect from 'use-deep-compare-effect';

const Marker: React.FC<google.maps.MarkerOptions> = options => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

interface GoogleMapWidgetProps extends google.maps.MapOptions {
  className?: string;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

export const GoogleMapWidget: React.FC<GoogleMapWidgetProps> = ({ className, ...options }) => {
  const [map, setMap] = React.useState<google.maps.Map>();

  function setRef(ref: HTMLDivElement | null) {
    if (ref && !map) {
      setMap(
        new window.google.maps.Map(ref, {
          disableDefaultUI: true,
          zoomControl: true,
          ...options,
        }),
      );
    }
  }

  useDeepCompareEffect(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  return (
    <Wrapper apiKey='AIzaSyAHQUNtvwZvPkPVnnMSBRUU6O8rJCaEtbw'>
      <div ref={setRef} className={className}>
        <Marker position={options.center} map={map} />
      </div>
    </Wrapper>
  );
};
