import React, { useState } from 'react';
import { cx } from 'src/utils/styles';
import { SpeedometerIcon } from 'src/shared/assets';
import css from './PlaybackSpeed.module.scss';

const availableSpeeds = [0.5, 0.75, 1, 1.5, 2];

interface PlaybackSpeedProps {
  playbackRate: number;
  onChangeSpeed: (speed: number) => void;
}

export const PlaybackSpeed: React.FC<PlaybackSpeedProps> = ({ playbackRate, onChangeSpeed }) => {
  const [showMenu, setShowMenu] = useState(false);

  const [videoCurrentSpeed, setVideoCurrentSpeed] = useState(playbackRate);

  const onShowMenu = () => {
    setShowMenu(true);
  };

  const onHideMenu = () => {
    setShowMenu(false);
  };

  const handleChangeSpeed = (speed: number) => () => {
    onChangeSpeed(speed);
    setVideoCurrentSpeed(speed);
  };

  return (
    <div className='ms-3 position-relative' onMouseEnter={onShowMenu} onMouseLeave={onHideMenu} role='button'>
      <SpeedometerIcon />
      {showMenu && (
        <div
          style={{
            position: 'absolute',
            right: '-50px',
            bottom: '100%',
            display: 'flex',
            flexDirection: 'column',
            background: '#ffffffd8',
            padding: '14px',
            whiteSpace: 'nowrap',
            fontSize: '12px',
            borderRadius: '5px',
            boxShadow: '0px 1px 4px rgba(187, 195, 211, 0.5)',
          }}
          onMouseEnter={onShowMenu}
        >
          <span className={css.title}>Playback speed</span>
          {availableSpeeds.map(speed => (
            <button
              onClick={handleChangeSpeed(speed)}
              key={speed}
              className={cx(css.playbackButton, videoCurrentSpeed === speed && css.active)}
            >
              {speed === 1 ? 'Normal' : `x${speed}`}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
