import React, { PropsWithChildren } from 'react';
import css from './FormFieldWrapper.module.scss';
import { cx } from 'src/utils/styles';

interface FormFieldWrapperProps {
  className?: string;
  errorMessage?: string;
  isErrorVisible?: boolean;
  isRequired?: boolean;
  title: string;
}

export const FormFieldWrapper: React.FC<PropsWithChildren<FormFieldWrapperProps>> = ({
  children,
  className,
  errorMessage,
  isErrorVisible,
  isRequired,
  title,
  ...rest
}) => {
  return (
    <div className={cx('text-break', css.wrapper, className)} {...rest}>
      {title && (
        <label className={cx('d-block', css.title)}>
          {title}&nbsp;
          {isRequired && (
            <span aria-label={'Required field'} className={cx('fw-bolder ms-1', css.required)} title={'Required field'}>
              *
            </span>
          )}
        </label>
      )}

      {children}

      {isErrorVisible && errorMessage && <div className={cx('mt-2 ts-fs-13', css.errors)}>{errorMessage}</div>}
    </div>
  );
};
