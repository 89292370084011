import { TeamStaffMember } from 'src/api/getClubTeams';
import { useClub } from 'src/context/useClub';
import { Card } from 'src/shared/widgets/Card';
import { RoundedImage } from 'src/shared/widgets/RoundedImage';
import { cx } from 'src/utils/styles';
import css from './StaffList.module.scss';

interface StaffMemberProps {
  staffMember: TeamStaffMember;
}

const StaffMember: React.FC<StaffMemberProps> = ({ staffMember: { avatarUrl, email, name, teamName } }) => (
  <div className={cx('d-flex w-100 align-items-center gap-3', css.wrapper)}>
    <RoundedImage name={name} url={avatarUrl} className={cx(css.avatar, 'flex-shrink-0 flex-grow-0')} />
    <div className='d-flex flex-column flex-grow-1 gap-1'>
      <p className='mb-0 ts-fw-500'>{name}</p>
      <p className='mb-0 ts-color-light'>{teamName}</p>
      <a className='ts-color-primary' href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  </div>
);

interface StaffListProps {
  className?: string;
}

export const StaffList: React.FC<StaffListProps> = ({ className }) => {
  const {
    club: { teams },
  } = useClub();
  const staffMembers = teams.flatMap(team => team.staff).filter(Boolean) as TeamStaffMember[];

  if (!staffMembers.length) return null;

  return (
    <Card className={className}>
      <h4 className='mb-3'>Our staff and key team members</h4>
      <div className='d-flex flex-column gap-4'>
        {staffMembers.map(staffMember => (
          <StaffMember key={staffMember.id} staffMember={staffMember} />
        ))}
      </div>
    </Card>
  );
};
