// import { apiClient } from 'src/shared/utils/apiClient';
import { apiClient } from 'src/utils/apiClient';
// import { dateToISONoTimezone } from 'src/shared/utils/dates';
import { dateToISONoTimezone } from 'src/utils/dates';

interface GetHighlightsResponse {
  code: number;
  error: boolean;
  message: string;
  highlights: {
    data: {
      created_at: DateTimeString;
      deleted_at: DateTimeString | null;
      high_canvas: string | null;
      high_end_time: string;
      high_id: number;
      high_start_time: string;
      high_tag: string | null;
      high_timestamp: string | null;
      high_thumbnail_url: string | null;
      high_type: string | null;
      high_video_url: string | null;
      tag_abbreviation: string;
      tag_category: string;
      tag_id: number;
      tag_name: string;
      team_avatar: string;
      // team_id: number;
      team_name: string;
      updated_at: DateTimeString | null;
      video_id: number;
      video_name: string;
      video_type: string;
      profile_avatar: string | null;
      profile_first_name: string | null;
      profile_last_name: string | null;
      team_code: string;
      user_id: number | null;
    }[];
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    links: { active: boolean; label: string; url: null | string }[];
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
    total: number;
  };
}
export interface GetHighlightsRequest {
  filters: {
    category?: string;
    collectionId?: string;
    fromDate?: Date;
    tagName?: string;
    tagId?: string;
    teamId?: string;
    toDate?: Date;
    videoId?: number;
    withVideo?: boolean;
    tagsIds?: number[];
    playerId?: number;
    siteCode: string;
  };
  sortAscending: boolean;
  sortBy: string;
  limit: number;
  page: number;
}

export async function getHighlights(request: GetHighlightsRequest): Promise<GetHighlightsResponse> {
  const filters: string[] = [];
  // if (request.filters.teamId) filters.push(`team_id=${request.filters.teamId}`);
  if (request.filters.tagId) filters.push(`tag_id=${request.filters.tagId}`);
  if (request.filters.fromDate) filters.push(`start_date=${dateToISONoTimezone(request.filters.fromDate)}`);
  if (request.filters.toDate) filters.push(`end_date=${dateToISONoTimezone(request.filters.toDate)}`);
  if (request.filters.collectionId) filters.push(`collection=${request.filters.collectionId}`);
  if (request.filters.category) filters.push(`category=${request.filters.collectionId}`);
  if (request.filters.videoId) filters.push(`video_id=${request.filters.videoId}`);
  if (request.filters.tagsIds?.length) filters.push(`tag_ids=${JSON.stringify(request.filters.tagsIds)}`);
  if (request.filters.tagName) filters.push(`name=${request.filters.tagName}`);
  if (request.filters.playerId) filters.push(`user_id=${request.filters.playerId}`);
  if (request.sortBy !== 'created') filters.push(`sort_name=${request.sortBy}`);

  filters.push(`limit=${request.limit}`);
  filters.push(`sorting=${request.sortAscending ? 'asc' : 'desc'}`);
  filters.push(`page=${request.page}`);
  filters.push(`with_video_only=${request.filters.withVideo ? '1' : '0'}`);
  filters.push('tag_abbreviation=HIGH');

  const queryString = filters.length > 0 ? `?${filters.join('&')}` : '';

  return apiClient(`/v1/website/highlights${queryString}`, { siteCode: request.filters.siteCode });
}
