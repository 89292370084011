import React from 'react';
import { MoreVertical } from 'react-feather';
import { ModalDialog } from 'src/shared/modal/ModalDialog';
import { cx } from 'src/utils/styles';
import css from './DataTable.module.scss';
import { DataTableColumnDef } from './DataTable';

interface DataTableItemDialogProps<T> {
  columns: DataTableColumnDef<T>[];
  dialogTitle: string;
  dataItem: T;
  keepKeyValueRender?: boolean;
  buttonRender?: JSX.Element;
  children?: React.ReactNode;
}

export function DataTableItemDialog<T>({
  children,
  columns,
  dialogTitle,
  dataItem,
  keepKeyValueRender,
  buttonRender,
}: DataTableItemDialogProps<T>) {
  const [opened, setOpened] = React.useState(false);

  function renderRow(column: DataTableColumnDef<T>) {
    const { key, dialogDataCell, title, dataCell } = column;

    if (dialogDataCell !== undefined) {
      return keepKeyValueRender ? (
        <tr key={key as string}>
          <td className='py-2 pe-3 ts-fw-500 align-middle text-nowrap'>{title}</td>
          <td className='py-2 align-middle'>{dialogDataCell(dataItem)}</td>
        </tr>
      ) : (
        dialogDataCell(dataItem)
      );
    }

    return (
      <tr key={key as string}>
        <td className='py-2 pe-5 ts-fw-500 align-top text-nowrap'>{title}</td>
        <td className='py-2 align-middle'>{dataCell(dataItem)}</td>
      </tr>
    );
  }

  const Button = buttonRender
    ? React.cloneElement(buttonRender, {
        onClick: () => setOpened(true),
      })
    : React.createElement(
        'button',
        {
          className: cx('p-0 bg-transparent', css.dialogOpenButton),
          onClick: () => setOpened(true),
        },
        <MoreVertical />,
      );

  return (
    <>
      {Button}

      {opened && (
        <ModalDialog title={dialogTitle} closeDialog={() => setOpened(false)}>
          <table className='w-100'>
            <tbody>{columns.map(renderRow)}</tbody>
          </table>
          {children}
        </ModalDialog>
      )}
    </>
  );
}
