import { ApiError } from 'src/utils/apiError';

type HTTPResponse<T = void | AnyObject> = T & {
  code: number;
  error: boolean;
  message: string;
};

type TypedRequestInit = RequestInit & {
  baseURL?: string;
  /**
   * @default true
   */
  siteCode?: string | null;
  /**
   * @default GET
   */
  method?: 'DELETE' | 'GET' | 'POST' | 'PATCH';
  /**
   * The JWT to use for HTTP requests.
   */
  token?: string;
};

export const BASE_API_URL = import.meta.env.VITE_TS_API_BASE_URL; //'https://api.teamsportz.pro';
const ADMIN_TOKEN = import.meta.env.VITE_API_ADMIN_TOKEN as string;

/**
 * A modified window.fetch to handle errors and transform the response to be consistent for all API calls.
 *
 * @note NO need to specify the method for GET requests.
 */
export async function apiClient<T>(endpoint: string, init?: TypedRequestInit): Promise<T> {
  try {
    const requestInit = init || {};

    requestInit.method = requestInit.method || 'GET';
    requestInit.headers = {
      Accept: 'application/json',
      'Admin-Token': ADMIN_TOKEN,
      ...requestInit.headers,
    };

    if (requestInit.token) {
      requestInit.headers = {
        Authorization: `Bearer ${requestInit.token}`,
        ...requestInit.headers,
      };
      delete requestInit.token;
    }

    if (requestInit.body && typeof requestInit.body === 'string') {
      requestInit.headers = {
        'Content-Type': 'application/json',
        ...requestInit.headers,
      };
    }

    const baseUrl = init?.baseURL || BASE_API_URL;
    const endpointWithQuery = requestInit.siteCode
      ? endpoint.includes('?')
        ? endpoint.concat(`&site_code=${requestInit.siteCode}`)
        : endpoint.concat(`?site_code=${requestInit.siteCode}`)
      : null;

    const response = await window.fetch(baseUrl + (endpointWithQuery || endpoint), requestInit);

    // Handle response that is not JSON.
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') < 0) {
      throw new ApiError({ message: await response.text(), code: response.status });
    }

    const data = (await response.json()) as HTTPResponse;
    if (data.error) {
      // The API is always expected to return an error object when there is an error. Equivalent of !response.ok
      let message = data.message;
      if ('details' in data) {
        message += ` ${Object.values(data.details).join(' - ')}`;
      }
      throw new ApiError({ message, code: data.code || response.status });
    }

    return data as T;
  } catch (error) {
    if (error instanceof ApiError) {
      throw new ApiError(error);
    }
    throw error;
  }
}
