import React, { useMemo } from 'react';
import { PlayCircle } from 'react-feather';
import { VIDEO_STORAGE_HOSTNAME } from 'src/constants';
import { SpinnerAnimation } from 'src/shared/widgets/SpinnerAnimation';
import { cx } from 'src/utils/styles';
import css from './HighlightEditorThumbnail.module.scss';
import { HighlightModal } from './HighlightModal';
import { CardTitle, CardSubtitle } from 'src/shared/widgets/Card';
import { format } from 'date-fns';
import { toTitleCase } from 'src/utils/strings';

export interface Highlight {
  created: Date;
  name: string;
  tag: string;
  tagAbbr: string;
  tagCategory: string;
  tagTimestamp: number;
  thumbnail: string | null;
  teamCode: string | null;
  teamName: string;
  id: number;
  url: string;
  videoId: number;
  player: { id: number; firstName: string; lastName: string; avatarUrl: string | null } | null;
  highType: string;
  videoName: string;
}

interface HighlightEditorThumbnailProps {
  className?: string;
  highlight: Highlight;
  withTagAbbr?: boolean;
  withoutShareButton?: boolean;
}

export const HighlightEditorThumbnail: React.FC<HighlightEditorThumbnailProps> = props => {
  const { className, highlight, withTagAbbr } = props;
  const isLoading = useMemo(() => !highlight.url, [highlight.url]);
  const [modalOpened, setModalOpened] = React.useState(false);

  return (
    <>
      <div className='mw-100'>
        <button
          className={cx('d-block position-relative ', css.thumbnailBox, className, isLoading && 'pe-none')}
          style={
            highlight.thumbnail
              ? { backgroundImage: `url("${VIDEO_STORAGE_HOSTNAME}${highlight.thumbnail}")` }
              : undefined
          }
          onClick={() => (isLoading ? null : setModalOpened(true))}
        >
          {withTagAbbr && (
            <p className={cx('position-absolute top-0 start-0 px-2 py-1 m-2 ts-fw-500 ts-fs-12', css.thumbnailBadge)}>
              {highlight.tagAbbr}
            </p>
          )}
          {isLoading && <SpinnerAnimation className={css.loader} />}
          <div
            className={cx(
              'position-absolute top-0 start-0 h-100 w-100 d-flex align-items-center justify-content-center',
              css.thumbnailOverlay,
            )}
          >
            <PlayCircle className={css.playIcon} width='40px' height='40px' />
          </div>
        </button>
        <div className={cx('flex-grow-1 flex-shrink-1 d-flex flex-column justify-content-sm-between p-2 pb-0')}>
          <CardSubtitle className={css.code}>{format(highlight.created, 'dd MMMM yyyy')}</CardSubtitle>
          <CardTitle>
            {toTitleCase(highlight.highType)} {highlight.videoName}
          </CardTitle>
        </div>
      </div>
      {modalOpened && (
        <HighlightModal
          close={setModalOpened}
          highlightVideoUrl={`${VIDEO_STORAGE_HOSTNAME}${highlight.url}` || ''}
          title={`${toTitleCase(highlight.highType)} ${highlight.videoName}`}
        />
      )}
    </>
  );
};
