import { CheckSquare, Square } from 'react-feather';
import css from './DropdownOption.module.scss';
import { cx } from 'src/utils/styles';

export interface DropdownOptionDef {
  id: string;
  label: string;
}

interface DropdownOptionProps<T> {
  option: T;
  selected: boolean;
  multiple?: boolean;
  onSelect(value: T): void;
}

export const DropdownOption = <T extends DropdownOptionDef | string>({
  selected,
  option,
  onSelect,
  multiple,
}: DropdownOptionProps<T>) => {
  const label = typeof option === 'string' ? option : option.label;

  return (
    <button
      aria-label={label}
      className={cx(
        'w-100 text-overflow text-start bg-transparent',
        css.dropdownOption,
        selected && css.selectedOption,
      )}
      disabled={multiple ? false : selected}
      title={label}
      type='button'
      onClick={() => onSelect(option)}
    >
      {multiple &&
        (selected ? (
          <CheckSquare className={cx('ts-color-primary align-middle me-2', css.icon)} />
        ) : (
          <Square className={cx('ts-color-primary align-middle me-2', css.icon)} />
        ))}
      {label}
    </button>
  );
};
