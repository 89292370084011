import React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { ApiError } from 'src/utils/apiError';
import { ErrorMessage } from 'src/shared/layout/ErrorMessage';
import { UnauthorisedPage } from 'src/shared/layout/UnauthorisedPage';
import { ErrorLoadingPage } from 'src/shared/layout/ErrorLoadingPage';
import { NoNetworkPage } from 'src/shared/layout/NoNetworkPage';

interface ErrorPageProps {
  error?: ApiError;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ error }) => {
  const routerError = useRouteError();
  const code = error?.code || (isRouteErrorResponse(routerError) && routerError.status);

  if (code === false && error instanceof Error) {
    return (
      <div className='my-3'>
        <ErrorMessage message={error.message} stack={error.stack} />
      </div>
    );
  }

  switch (code) {
    case 401:
      return <UnauthorisedPage />;
    case 408:
      return <ErrorLoadingPage />;
    case 503:
      return <NoNetworkPage />;

    default:
      return (
        <div className='my-3'>
          <ErrorMessage message={error?.message || 'Something went wrong :('} stack={error?.stack} />
        </div>
      );
  }
};
