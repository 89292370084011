import { apiClient } from 'src/utils/apiClient';

interface ClubMappingsResponse {
  results: {
    org_id: number;
    org_site_code: string;
    org_site_url: string;
  }[];
}

export async function getClubsMappings(domainName: string, siteCode: string): Promise<string | null> {
  const { results } = await apiClient<ClubMappingsResponse>(
    `/v1/website/mapping?url=${domainName}${siteCode ? `&code=${siteCode}` : ''}`,
  );
  const code = results[0]?.org_site_code || null;
  return code;
}
