import React, { useState, useRef } from 'react';
import { RippleAnimation } from 'src/shared/layout/RippleAnimation';
import { ModalDialog } from 'src/shared/modal/ModalDialog';
import styles from './HighlightModal.module.scss';
import ReactPlayer from 'react-player/lazy';
import { useIsMobile } from 'src/utils/hooks/useIsMobile';
import VideoPlayerControls from './VideoPlayerControls';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

export const HighlightModal = ({
  close,
  highlightVideoUrl,
  title,
}: {
  close: React.Dispatch<React.SetStateAction<boolean>>;
  highlightVideoUrl: string;
  title: string;
}) => {
  const [playerState, setPlayerState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
  });
  const { playing, muted, volume, playbackRate, played, seeking } = playerState;

  const format = (seconds: number) => {
    if (isNaN(seconds)) return '0:00';

    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }

    return `${mm}:${ss}`;
  };

  const handle = useFullScreenHandle();
  React.useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      switch (e.code) {
        case 'Escape':
          return handle.exit();
      }
    }
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handle]);

  // const isMobile = useIsMobile(CSS_BREAKPOINTS.sm);
  const isMobile = useIsMobile();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const playerRef = useRef<any | null>(null);

  const handleRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
  };
  const handleFastForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 5);
  };
  const handlePlayPause = () => {
    setPlayerState({ ...playerState, playing: !playerState.playing });
  };
  const handleMute = () => {
    setPlayerState({ ...playerState, muted: !playerState.muted });
  };
  const handleVolumeChange = (e: number) => {
    setPlayerState({
      ...playerState,
      volume: e,
      muted: e === 0,
    });
  };
  const handlePlaybackRateChange = (rate: number) => {
    setPlayerState({ ...playerState, playbackRate: rate });
  };
  const toggleFullScreen = () => {
    if (handle.active) {
      handle.exit();
      return;
    }
    handle.enter();
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleProgress = (changeState: any) => {
    if (!seeking) {
      setPlayerState({ ...playerState, ...changeState });
    }
  };
  const handleSeekChange = (e: number) => {
    setPlayerState({ ...playerState, played: e });
  };
  const handleSeekMouseDown = (e: number) => {
    setPlayerState({ ...playerState, seeking: true });
    playerRef.current.seekTo(e);
  };
  const handleSeekMouseUp = (e: number) => {
    setPlayerState({ ...playerState, seeking: false });
    playerRef.current.seekTo(e);
  };
  const handleVideoEnd = () => {
    setPlayerState({ ...playerState, playing: false });
  };

  const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : '0:00';
  const duration = playerRef.current ? playerRef.current.getDuration() : '0:00';

  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);

  return (
    <ModalDialog closeDialog={() => close(false)} className={styles.modal} modalContentClassName='h-100' title={title}>
      {!highlightVideoUrl && (
        <div className='d-flex align-items-center justify-content-center h-100'>
          <RippleAnimation className='d-block my-5 mx-auto ts-color-primary' />
        </div>
      )}
      {highlightVideoUrl && (
        <FullScreen handle={handle}>
          <div className='position-relative fullscreen-container'>
            <ReactPlayer
              ref={playerRef}
              muted={muted}
              url={highlightVideoUrl}
              {...(isMobile || handle.active ? { width: '100%', height: 'auto' } : {})}
              playing={playing}
              volume={volume}
              playbackRate={playbackRate}
              onProgress={handleProgress}
              onEnded={handleVideoEnd}
            />
            <VideoPlayerControls
              videoUrl={highlightVideoUrl}
              onPlayPause={handlePlayPause}
              isVideoPlaying={playing}
              onRewind={handleRewind}
              onFastForward={handleFastForward}
              muted={muted}
              onMute={handleMute}
              onChangeVolume={handleVolumeChange}
              volume={volume}
              playbackRate={playbackRate}
              onPlaybackRateChange={handlePlaybackRateChange}
              onToggleFullScreen={toggleFullScreen}
              isFullScreen={handle.active}
              played={played}
              onSeek={handleSeekChange}
              onSeekMouseDown={handleSeekMouseDown}
              onSeekMouseUp={handleSeekMouseUp}
              elapsedTime={elapsedTime}
              totalDuration={totalDuration}
            />
          </div>
        </FullScreen>
      )}
    </ModalDialog>
  );
};
