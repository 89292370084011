import React from 'react';
import css from './DropdownPanel.module.scss';
import { cx } from 'src/utils/styles';

interface DropdownPanelProps {
  className?: string;
  isOpen: boolean;
  value?: string;
  isSmall?: boolean;
  children: React.ReactNode;
}

export const DropdownPanel: React.FC<DropdownPanelProps> = ({ className, children, isOpen, isSmall }) => {
  if (!isOpen) return null;

  return (
    <div className={cx('position-fixed', css.dropdownPanel, className)}>
      <div className={cx(css.container, isSmall && css.small)}>{children}</div>
    </div>
  );
};
