import { Form, Input, Radio, DatePicker, Select, Button } from 'antd';
import { Card, CardTitle } from 'src/shared/widgets/Card';
import { useClub } from 'src/context/useClub';
import useForm from 'antd/es/form/hooks/useForm';
import { FormData } from 'src/pages/CompetitionForm/index';
import { differenceInYears } from 'date-fns';
import { useState } from 'react';
const { Option } = Select;
import { PrimaryButton } from 'src/shared/buttons';

export interface FormProps {
  submit: (values: FormData) => Promise<void>;
  dobRange: {
    oldestDob: string | null;
    youngestDob: string | null;
  };
  category: string;
  loading?: boolean;
}

const DefaultForm: React.FC<FormProps> = ({ submit, dobRange, loading, category }) => {
  const [form] = useForm();
  const [isOver18, setIsOver18] = useState<boolean | null>(null);

  const {
    club: { info },
  } = useClub();

  const calculateIsOver18 = (dateOfBirth: Date) => {
    const currentDate = new Date();
    return differenceInYears(currentDate, dateOfBirth) >= 18;
  };

  const handleDateOfBirthChange = (_dateOfBirth: Date, dateString: string | string[]) => {
    const parsedDate = new Date(dateString as string);
    if (parsedDate) {
      setIsOver18(calculateIsOver18(parsedDate));
    } else {
      setIsOver18(null);
    }
  };

  return (
    <Card>
      <CardTitle>Registration</CardTitle>
      <Form form={form} name='registration' onFinish={submit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Form.Item
          label='Player First Name'
          name='firstName'
          rules={[{ required: true, message: 'Please enter the player first name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Player Last Name'
          name='lastName'
          rules={[{ required: true, message: 'Please enter the player last name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Player Gender'
          name='gender'
          rules={[
            { required: true },
            () => ({
              validator(_, value) {
                const isGenderMatch =
                  (value === 'male' && ['men', 'boys'].includes(category)) ||
                  (value === 'female' && ['girls', 'women'].includes(category));

                if (isGenderMatch || category === 'mixed' || !category) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(`This competition is for ${category} only`));
              },
            }),
          ]}
        >
          <Radio.Group>
            <Radio value='male'>Male</Radio>
            <Radio value='female'>Female</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label='Player Date of Birth'
          name='dob'
          rules={[
            { required: true, message: 'Please select your date of birth' },
            () => ({
              validator(_, value) {
                if (!(dobRange.oldestDob && dobRange.youngestDob)) {
                  return Promise.resolve();
                }
                const dateOfBirth = new Date(value);
                const oldestDob = new Date(dobRange.oldestDob);
                const youngestDob = new Date(dobRange.youngestDob);
                if (dateOfBirth > oldestDob && dateOfBirth < youngestDob) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('DOB does not fall within the age limit for this competition'));
              },
            }),
          ]}
        >
          <DatePicker style={{ width: '100%' }} onChange={handleDateOfBirthChange} />
        </Form.Item>
        {isOver18 === true && (
          <>
            <Form.Item
              label='Player Email Address'
              name='playerEmail'
              rules={[
                { required: isOver18 === true, message: 'Please enter the player email address' },
                { type: 'email', message: 'Please enter a valid email address' },
              ]}
            >
              <Input type='email' />
            </Form.Item>
          </>
        )}
        {isOver18 === false && (
          <>
            <Form.Item
              label='Parent/Guardian Email Address'
              name='parent1Email'
              rules={[
                { required: isOver18 === false, message: 'Please enter parent/guardian email address' },
                { type: 'email', message: 'Please enter a valid email address' },
              ]}
            >
              <Input type='email' />
            </Form.Item>
          </>
        )}
        <Form.Item
          label='Emergency Contact First Name'
          name='emergencyFirstName1'
          rules={[{ required: true, message: 'Please enter emergency contact first name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Emergency Contact Last Name'
          name='emergencyLastName1'
          rules={[{ required: true, message: 'Please enter emergency contact last name' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Emergency Contact Mobile Number'
          name='emergencyMobileNumber1'
          rules={[{ required: true, message: 'Please enter emergency contact mobile number' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label='Relationship'
          name='relationship1'
          rules={[{ required: true, message: 'Please select your relationship' }]}
        >
          <Select>
            <Option value='mother'>Mother</Option>
            <Option value='father'>Father</Option>
            <Option value='step-mother'>Step-Mother</Option>
            <Option value='step-father'>Step-Father</Option>
            <Option value='guardian'>Guardian</Option>
            <Option value='grandmother'>Grandmother</Option>
            <Option value='grandfather'>Grandfather</Option>
            <Option value='brother'>Brother</Option>
            <Option value='sister'>Sister</Option>
            <Option value='auntie'>Auntie</Option>
            <Option value='uncle'>Uncle</Option>
            <Option value='cousin'>Cousin</Option>
            <Option value='friend'>Friend</Option>
            <Option value='other'>Other</Option>
          </Select>
        </Form.Item>
        <div>
          <CardTitle className='mb-2'>Photography, Filming and Live Streaming Parent Consent</CardTitle>
        </div>
        <ul>
          <li> I consent to the {info.name} photographing or videoing my involvement in sporting activities.</li>
          <li>
            I consent that I have read, or been made aware of, the organisation's photography and videoing policy.
          </li>
        </ul>
        <div>Do you agree to all of the photography consents detailed above?</div>
        <Form.Item
          className='mt-3'
          name='photographyConsentParent'
          rules={[{ required: true, message: 'Please select your consent' }]}
        >
          <Radio.Group>
            <Radio value='agree'>Yes, I consent</Radio>
            <Radio value='disagree'>No, I don't consent</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button style={{ border: 0 }} htmlType='submit' className='w-100 p-0'>
            <PrimaryButton loading={loading} className='w-100'>
              Submit
            </PrimaryButton>
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default DefaultForm;
