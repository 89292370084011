import { Mail, MapPin, Phone } from 'react-feather';
import { useClub } from 'src/context/useClub';
import { Card } from 'src/shared/widgets/Card';

interface ContactInfoProps {
  className?: string;
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ className }) => {
  const {
    club: { info },
  } = useClub();

  return (
    <Card className={className}>
      <h4 className='mb-3'>Contact Info</h4>
      <p className='fs-6'>Get in touch with us! We'd love to hear from you.</p>
      <div className='d-flex gap-3 justify-content-sm-evenly flex-wrap'>
        {info.email && (
          <a className='d-flex gap-2 align-items-center' href={`mailto:/${info.email}`}>
            <Mail className='ts-color-primary' />
            {info.email}
          </a>
        )}
        {info.address && (
          <a className='d-flex gap-2 align-items-center' href={`https://maps.google.com?q=${info.address}`}>
            <MapPin className='ts-color-primary' />
            {info.address}
          </a>
        )}
        {info.phoneNumber && (
          <a className='d-flex gap-2 align-items-center'>
            <Phone className='ts-color-primary' />
            {info.phoneNumber}
          </a>
        )}
      </div>
    </Card>
  );
};
