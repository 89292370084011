import { apiClient } from 'src/utils/apiClient';

export interface FormData {
  allergies: string;
  britishPassport: string;
  consentsAgree: boolean;
  county: string;
  disabilities: string;
  dob: string;
  emergencyFirstName1: string;
  emergencyFirstName2: string;
  emergencyLastName1: string;
  emergencyLastName2: string;
  emergencyMobileNumber1: string;
  emergencyMobileNumber2: string;
  ethnicity: string;
  familyDeath: string;
  firstName: string;
  gender: string;
  headshotPhoto: File;
  homePostcode: string;
  jerseySize: string;
  lastName: string;
  medicalDetails: string;
  parent1Email: string;
  parent2Email: string;
  photographyConsentParent: string;
  photographyConsentPlayer: string;
  playerEmail: string;
  relationship1: string;
  relationship2: string;
  talentBehavioursAgree: boolean;
}

interface Response {
  user_id: number;
}

export async function createEntry(data: FormData, competitionId: number, siteCode: string): Promise<number> {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'headshotPhoto') {
      formData.append(key, value as File);
    } else if (typeof value === 'boolean') {
      formData.append(key, value ? '1' : '0');
    } else {
      formData.append(key, value);
    }
  });
  const response = await apiClient<Response>(`/v1/website/entries/${competitionId}`, {
    method: 'POST',
    body: formData,
    siteCode,
  });

  return response.user_id;
}
